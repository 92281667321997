import { isBrowser } from '@landr/maestro';
import {
    SessionsPlansMap,
    DistributionFreemiumCode,
    PricingTabsEnum,
    BillingCommitment,
    Languages,
} from '@landr/pricing';

export const PricingUris = [
    '/pricing',
    '/en/pricing',
    '/fr/tarifs',
    '/de/preise',
    '/pt/preco',
    '/ja/料金',
    '/it/tariffe',
    '/es/precios',
    '/ja/%E6%96%99%E9%87%91',
];

const setHomePageParam = (homePage: string | null, searchParams: URLSearchParams): void => {
    if (homePage) {
        searchParams.set('homePage', homePage);
    }
};

const setCancelUrlParam = (currentTab: PricingTabsEnum, searchParams: URLSearchParams): void => {
    if (!isBrowser) {
        return;
    }
    searchParams.set('cancelUrl', `/pricing/${currentTab}`);
};

export const getCheckoutLinkFromPlanCode = ({
    code,
    commitment,
    couponCode,
    homePage,
    currentTab,
    language,
    isFreeTrial,
}: {
    code: string;
    commitment: BillingCommitment;
    homePage: string | null;
    currentTab: PricingTabsEnum;
    language: Languages;
    couponCode?: string;
    isFreeTrial?: boolean;
}): string => {
    // Web App URLs now supports plan code without this suffix (for stripe and fusebill)
    code = code.replace('_annual_billed_monthly', '');
    let pathname = `/payment/subscription/checkout/${code}/${commitment.toLowerCase()}`;
    const searchParams = new URLSearchParams();

    searchParams.set('isSignup', 'true');
    searchParams.set('locale', language);
    setHomePageParam(homePage, searchParams);

    if (isFreeTrial) {
        searchParams.set('isFreeTrial', 'true');
    }

    if (couponCode) {
        pathname += `/${couponCode}`;
    }

    if (code === SessionsPlansMap.Free) {
        return `${process.env.GATSBY_APP_ENDPOINT}/sessions?${searchParams.toString()}`;
    }

    if (code === DistributionFreemiumCode) {
        // If Distro free tier, send to Distribution Overview page
        return `${process.env.GATSBY_APP_ENDPOINT}/releases/overview?${searchParams.toString()}`;
    }

    setCancelUrlParam(currentTab, searchParams);

    return `${process.env.GATSBY_APP_ENDPOINT}${pathname}?${searchParams.toString()}`;
};

export const getProductCheckoutLinkFromProductCode = ({
    code,
    homePage,
    currentTab,
    language,
    couponCode,
}: {
    code: string;
    homePage: string | null;
    currentTab: PricingTabsEnum;
    language: Languages;
    couponCode?: string;
}): string => {
    let pathname = `/payment/product/checkout/${code}`;
    const searchParams = new URLSearchParams();

    if (couponCode) {
        pathname += `/${couponCode}`;
    }

    searchParams.set('isSignup', 'true');
    searchParams.set('locale', language);
    setHomePageParam(homePage, searchParams);
    setCancelUrlParam(currentTab, searchParams);

    return `${process.env.GATSBY_APP_ENDPOINT}${pathname}?${searchParams.toString()}`;
};
