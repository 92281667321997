import React from 'react';
import './Spinner.scss';

type SpinnerProps = {
    className?: string;
    spinnerRef: React.RefObject<any>;
};

const Spinner = (props: SpinnerProps) => {
    const { className, spinnerRef } = props;
    return <div className={className} ref={spinnerRef} />;
};

export { Spinner };
