import { AppContextType } from 'context/AppContext';
import {
    PrismicTitleFieldType,
    SliceContext,
    PrismicFluidImageField,
    PrismicImageField,
    PrismicExternalLink,
} from '../types';
import { SupportedPrismicSlicesEnum } from '../enums';

export enum Versions {
    original = 'original',
    mastered = 'mastered',
}

export type Item = {
    mastering_demos_original: PrismicExternalLink;
    mastering_demos_mastered: PrismicExternalLink;
    mastering_demos_image: PrismicImageField;
    mastering_demos_imageSharp: PrismicFluidImageField;
    mastering_demos_style: PrismicTitleFieldType;
    mastering_demos_track_title: PrismicTitleFieldType;
};
export type MasteringDemosProps = {
    slice: {
        type: SupportedPrismicSlicesEnum.mastering_demos;
        primary: {
            mastering_demos_original_button_copy: string;
            mastering_demos_mastered_button_copy: string;
            mastering_demos_title: PrismicTitleFieldType;
        };
        fields: Item[];
    };
    context: AppContextType;
    sliceContext: SliceContext;
};
