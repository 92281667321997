import React, { useState, useEffect } from 'react';
import { ApplicationEnum } from '@landr/core.models';
import { EventId } from 'enum/eventId';
import createFlags from 'flag';
import { FeatureFlagsEnum } from 'enum/featureFlags';
import {
    areDevToolsActive,
    FlagUpdatePayload,
    getCachedForcedFlags,
    sendToDevTools,
    subscribeFromDevTools,
} from '@landr/core';
import { log } from '../log';
import { APP_VERSION } from '../constants';

const areGuestSiteDevToolsActive = () => typeof window !== 'undefined' && areDevToolsActive();

/**
 * Send an event to LANDR devtools with the initial application details
 */
export function setupLandrDevTools() {
    if (areGuestSiteDevToolsActive()) {
        sendToDevTools('set-state', {
            name: ApplicationEnum.GuestSite,
            version: APP_VERSION,
            config: {
                /**
                 * Pass 1 property to validate it works well.
                 * TODO: https://mixgenius.atlassian.net/browse/ECO-303
                 */
                GATSBY_MONOLITH_ENDPOINT: process.env.GATSBY_MONOLITH_ENDPOINT,
            },
        });
    }
}
setupLandrDevTools();

export type FeatureFlags = {
    [key in FeatureFlagsEnum]: boolean;
};

const defaultFeatureFlags = {
    [FeatureFlagsEnum.DistributionCoverArtGenerator]: false,
    [FeatureFlagsEnum.MasteringStandardPlugin]: false,
    [FeatureFlagsEnum.StudioProFreeTrial]: false,
    [FeatureFlagsEnum.StudioEssentials]: false,
    [FeatureFlagsEnum.StudioEssentialsThreePlans]: false,
};

const { FlagsProvider: InternalFlagsProvider, Flag, useFlag, useFlags } = createFlags<FeatureFlags>();

const FlagsProvider: React.FC = ({ children }) => {
    const [featureFlags, setFeatureFlags] = useState(defaultFeatureFlags);

    // Update devtools when featureFlags state changes
    useEffect(() => {
        const cachedForcedFlags = getCachedForcedFlags(ApplicationEnum.GuestSite);

        if (areGuestSiteDevToolsActive()) {
            sendToDevTools('set-state', {
                name: ApplicationEnum.GuestSite,
                featureFlags: { ...defaultFeatureFlags, ...featureFlags },
                forcedFlags: cachedForcedFlags,
            });
        }
    }, [featureFlags]);

    useEffect(() => {
        try {
            fetch(`${process.env.GATSBY_KUBERNETES_ENDPOINT}/featureflag/api/v2/featureflag`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            })
                .then((response) =>
                    response.json().then((featureFlagsFromApi) =>
                        setFeatureFlags({
                            ...featureFlags,
                            ...featureFlagsFromApi,
                        }),
                    ),
                )
                .catch(() => {
                    setFeatureFlags(featureFlags);
                })
                .finally(() => {
                    if (areGuestSiteDevToolsActive()) {
                        subscribeFromDevTools(
                            'feature-flags-updated',
                            ({ payload }: { payload: FlagUpdatePayload }) => {
                                if (payload.name !== ApplicationEnum.GuestSite) {
                                    return;
                                }

                                setFeatureFlags({
                                    ...featureFlags,
                                    ...payload.forcedFlags,
                                });
                            },
                        );
                    }
                });
        } catch (error) {
            log.error('Feature flags failed', EventId.FeatureFlagsFailed, error);
        }
    }, []);

    return <InternalFlagsProvider flags={featureFlags}>{children}</InternalFlagsProvider>;
};

export { FlagsProvider, Flag, useFlag, useFlags };
