import React, { useEffect, useRef, useState } from 'react';
import { Box, ExperimentalProvider, Heading, ResponsiveProvider } from '@landr/maestro';
import { MaestroThemeProvider } from '@landr/maestro-legacy';
import {
    BillingCommitment,
    Environment,
    Languages,
    PricingProvider,
    PricingTabs,
    PricingTabsEnum,
    useStudioEssentialsSubscription,
} from '@landr/pricing';
import { useFlag } from 'context/FlagsContext';
import { FeatureFlagsEnum } from 'enum/featureFlags';
import { PageContextType } from 'types';
import { navigate } from 'gatsby';
import {
    addTrailingSlash,
    getDecodedPathname,
    getTwoLetterLanguageCode,
    pricingTabToUid,
    pricingUidToTab,
} from 'helpers';
import { ApplicationEnum } from '@landr/core.models';
import { getCheckoutLinkFromPlanCode, getProductCheckoutLinkFromProductCode } from 'helpers/pricingLinkUtils';
import { useAppContext } from 'context/AppContext';
import { analyticsV2, CtaGoal, CtaType, InteractionSource } from 'analytics';
import { useConvertExperiment } from 'hooks/useConvertExperiment';
import { customTheme } from '../../../styles/customTheme';
import { log } from '../../../log';
import { SupportedPrismicSlicesEnum } from '../enums';
import { useAuthentication } from '../../Authentication';
import { StyledPricingContentBox } from './styled';
import './overrides.scss';

const logger = log.getInstance('PricingTab');

export type PricingSliceProps = {
    pageContext: PageContextType;
    location: Location;
    slice: {
        type: SupportedPrismicSlicesEnum.pricing;
        primary: {
            /* eslint-disable @typescript-eslint/naming-convention */
            pricing_title: string;
        };
    };
};

const updatePath = (nextTab: PricingTabsEnum, language: Languages, pageContext: PageContextType) => {
    const nextTabInCurrentLanguage = pricingTabToUid[nextTab][language];
    const path = `${language}/${pageContext.uid}/${nextTabInCurrentLanguage}${window.location.search}`;
    navigate(path);
    return path;
};

const getAnalyticsCtaText = (nextTab: PricingTabsEnum) => {
    switch (nextTab) {
        case PricingTabsEnum.Bundle:
            return 'LANDR Studio';
        case PricingTabsEnum.Mastering:
            return 'Mastering';
        case PricingTabsEnum.Distribution:
            return 'Distribution';
        case PricingTabsEnum.Samples:
            return 'Samples';
        case PricingTabsEnum.Plugins:
            return 'Plugins';
        case PricingTabsEnum.Sessions:
            return 'Sessions';
        case PricingTabsEnum.Courses:
            return 'Education';
        default:
            return nextTab;
    }
};

export const getLocationTabUid = (location: Location): string | undefined => {
    const decodedLocation = addTrailingSlash(getDecodedPathname(location.pathname));
    return Object.keys(pricingUidToTab).find((pricingUid) => decodedLocation.includes(`/${pricingUid}/`));
};

export const PricingTabToHomePageEnum = {
    [PricingTabsEnum.Mastering]: 'Mastering',
    [PricingTabsEnum.Distribution]: 'Distribution',
    [PricingTabsEnum.Sessions]: 'Sessions',
    [PricingTabsEnum.Samples]: 'Samples',
    [PricingTabsEnum.Courses]: 'Education',
    [PricingTabsEnum.Plugins]: 'Plugins',
    [PricingTabsEnum.Bundle]: null,
};

function getSelectedTab(location: Location): PricingTabsEnum {
    getLocationTabUid(location);
    const tabUid = getLocationTabUid(location);
    return tabUid ? pricingUidToTab[tabUid as PricingTabsEnum] : PricingTabsEnum.Bundle;
}

function waitForFirstPromoterScript() {
    const timeoutPromise = new Promise<void>((resolve) => {
        setTimeout(resolve, 5000);
    });

    const readyPromise = new Promise<void>((resolve) => {
        if (!window.fpr) {
            return resolve();
        }
        window.fpr('onReady', resolve);
    });

    // Always resolve the promise after 5 seconds even if readyPromise never resolve. This is a failsafe
    return Promise.race([readyPromise, timeoutPromise]);
}

export const Pricing: React.FC<PricingSliceProps> = ({ pageContext, location, slice }) => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { pricing_title } = slice.primary;
    const language = getTwoLetterLanguageCode(pageContext.lang) as Languages;
    const [selectedTab, setSelectedTab] = useState(() => getSelectedTab(location));
    const { signupUrl, authorizationToken } = useAuthentication();
    const { setHomePage, resetHomePage, homePage } = useAppContext();
    const modalHeaderRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;

    const isMasteringStandardPluginAvailable = useFlag([FeatureFlagsEnum.MasteringStandardPlugin]);
    const isStudioProFreeTrialEnabled = useFlag([FeatureFlagsEnum.StudioProFreeTrial]);
    const isCoverArtGeneratorEnabled = useFlag([FeatureFlagsEnum.DistributionCoverArtGenerator]);
    const isStudioEssentialsThreePlansEnabled = useFlag([FeatureFlagsEnum.StudioEssentialsThreePlans]);

    const { isStudioEssentialsSubscriber } = useStudioEssentialsSubscription({
        env: process.env.GATSBY_ENV as Environment,
        authorizationToken,
    });
    // Force enable Studio Essentials for Studio Essentials subscribers;
    let isStudioEssentialsEnabled = isStudioEssentialsSubscriber;
    // CORE-12423: Pricing Studio Essentials Convert Experiment
    const studioEssentialsConvertExperiment = useConvertExperiment({
        analytics: analyticsV2,
        experimentId: process.env.GATSBY_CONVERT_EXPERIMENT_CORE_12423_STUDIO_ESSENTIALS_ENABLED_EXPERIMENT_ID ?? '',
    });
    if (studioEssentialsConvertExperiment && studioEssentialsConvertExperiment.variationId) {
        if (
            studioEssentialsConvertExperiment.variationId ===
                process.env.GATSBY_CONVERT_EXPERIMENT_CORE_12423_STUDIO_ESSENTIALS_ENABLED_VARIANT_B_ID ??
            ''
        ) {
            isStudioEssentialsEnabled = true;
        }
    }

    const handleSubscriptionPurchase = async ({
        code,
        commitment,
        couponCode,
        isFreeTrial,
    }: {
        code: string;
        commitment: BillingCommitment;
        couponCode?: string;
        isFreeTrial?: boolean;
    }) => {
        await waitForFirstPromoterScript();
        typeof window !== 'undefined' &&
            window.open(
                getCheckoutLinkFromPlanCode({
                    code,
                    commitment,
                    couponCode,
                    homePage,
                    currentTab: selectedTab,
                    language,
                    isFreeTrial,
                }),
                '_self',
            );
    };

    const handleOnSignUp = () => {
        typeof window !== 'undefined' && window.open(signupUrl, '_self');
    };

    const handleProductPurchase = ({ code, couponCode }: { code: string; couponCode?: string }) => {
        typeof window !== 'undefined' &&
            window.open(
                getProductCheckoutLinkFromProductCode({
                    code,
                    homePage,
                    currentTab: selectedTab,
                    language,
                    couponCode,
                }),
                '_self',
            );
    };
    const updateHomePageFromTabValue = (nextTab: PricingTabsEnum) => {
        const homePage = PricingTabToHomePageEnum[nextTab];
        if (homePage) {
            setHomePage(homePage);
        } else {
            resetHomePage();
        }
    };
    const handleTabSelected = (nextTab: PricingTabsEnum) => {
        const destinationPath = updatePath(nextTab, language, pageContext);
        setSelectedTab(nextTab);
        updateHomePageFromTabValue(nextTab);

        analyticsV2.trackCTAClicked({
            ctaGoal: CtaGoal.ViewPricing,
            ctaType: CtaType.Button,
            interactionSource: InteractionSource.PricingNav,
            ctaText: getAnalyticsCtaText(nextTab),
            ctaDestination: destinationPath,
        });
    };

    useEffect(() => {
        const selectedTab = getSelectedTab(location);
        setSelectedTab(selectedTab);
        updateHomePageFromTabValue(selectedTab);
    }, [location]);

    const convertExperiment = useConvertExperiment({
        analytics: analyticsV2,
        experimentId: process.env.CONVERT_EXPERIMENT_DIS_10934_ALC_EXPERIMENT_ID ?? '',
    });

    const isDistributionAlcExperimentVariantEnabled = convertExperiment
        ? convertExperiment.variationId === process.env.CONVERT_EXPERIMENT_DIS_10934_ALC_EXPERIMENT_VARIANT_A_ID
        : false;

    return (
        <MaestroThemeProvider theme={customTheme}>
            <ResponsiveProvider theme={customTheme}>
                <ExperimentalProvider
                    experiments={{
                        tabsArrow: true,
                    }}
                >
                    <PricingProvider
                        applicationName={ApplicationEnum.GuestSite}
                        env={process.env.GATSBY_ENV as Environment}
                        language={language}
                        log={logger}
                        locationPathname={location.pathname}
                        onSubscriptionPurchase={handleSubscriptionPurchase}
                        onProductPurchase={handleProductPurchase}
                        onSignUp={handleOnSignUp}
                        authorizationToken={authorizationToken}
                        showMasteringStandardPluginOffer={isMasteringStandardPluginAvailable}
                        isStudioProFreeTrialEnabled={isStudioProFreeTrialEnabled}
                        isStudioEssentialsEnabled={isStudioEssentialsEnabled}
                        isStudioEssentialsThreePlansEnabled={isStudioEssentialsThreePlansEnabled}
                    >
                        <StyledPricingContentBox>
                            <Box maxWidth="1200px" width="100%">
                                <Box flexDirection="column" alignSelf="flex-center" ref={modalHeaderRef}>
                                    <Box alignSelf="flex-start" width="100%">
                                        <Heading as="h1" size="jb">
                                            {pricing_title}
                                        </Heading>
                                    </Box>
                                </Box>
                                <PricingTabs
                                    selectedTab={selectedTab}
                                    onSelectTab={handleTabSelected}
                                    isSessionsFreePlanDisabled={false}
                                    modalHeaderRef={modalHeaderRef}
                                    isCoverArtGeneratorEnabled={isCoverArtGeneratorEnabled}
                                    isDistributionAlcExperimentVariantEnabled={
                                        isDistributionAlcExperimentVariantEnabled
                                    }
                                />
                            </Box>
                        </StyledPricingContentBox>
                    </PricingProvider>
                </ExperimentalProvider>
            </ResponsiveProvider>
        </MaestroThemeProvider>
    );
};
